import React from 'react';
import { toast } from 'react-toastify';
import './ConfirmModal.scss';
import { useRouteMatch } from 'react-router-dom';
import { useStore } from '../../../Containers/StorePage/hooks/useStore';
import { itemsToBuyCount } from '../../../Containers/StorePage/components/supportComponents/BuySection';
import { Button } from '../../Ui/v2';
import { TOKEN_TYPE, CURRENCIES, CURRENCY_TYPE } from '../../../Constants';
import { getBalanceCount } from '../../../Helpers';
import { useApp } from '../../../Data/AppContext';
import { buyPack } from '../../../Containers/StorePage/services/buy';
import { useUser } from '../../../Hooks/useUser';
import { CancelButton } from '../AffiliateModal/CancelButton/CancelButton';
import { getUserNumberBalance } from '../../../Containers/AuctionPage/AuctionAssetPage/AuctionAssetBidForm';

export function ConfirmModal() {
    const { dmpBalance, waxBalance, hidePopupHandler } = useApp();

    const { params = {} } = useRouteMatch('/Store/:productId') || {};
    const { data, refetch } = useStore(params.productId);
    const activeUser = useUser();

    const [card] = data;
    const itemsLeft = card?.asset_ids.length ?? 0;

    const itemsCount = sessionStorage.getItem(itemsToBuyCount);
    const tokenName =
        card.price_type === TOKEN_TYPE.DMP ? CURRENCIES.vDMP : CURRENCIES.WAX;
    const price = getBalanceCount(card.price_type, card.price_per_asset);
    const totalPrice = price * itemsCount;

    const userBalance = tokenName === CURRENCIES.vDMP ? dmpBalance : waxBalance;

    const isEnoughTokens =
        getUserNumberBalance(
            userBalance,
            card.price_type === TOKEN_TYPE.DMP ? 'DMP' : 'WAX'
        ) >= totalPrice;

    const handleSubmit = async () => {
        try {
            await buyPack({ activeUser, quantity: totalPrice, card });
            toast.success('Purchase successful');
            sessionStorage.setItem(itemsToBuyCount, 1);
            hidePopupHandler();
        } catch (error) {
            toast.error(error.message);
        }
    };
    if (!itemsLeft) {
        return (
            <div className="confirm-wrapper">
                <div className="confirm-title">Ooops</div>
                <div className="confirm-total confirm-text-base confirm-error">
                    No items left.
                </div>
                <CancelButton />
            </div>
        );
    }
    return (
        <div className="confirm-wrapper">
            <div className="confirm-title">Сonfirm your purchase</div>
            <div className="confirm-description confirm-text-base">
                {card.name}. {itemsCount} items ({price}{' '}
                {CURRENCY_TYPE[tokenName]} each)
            </div>
            <div className="confirm-total confirm-text-base">
                Total {itemsCount * price} {CURRENCY_TYPE[tokenName]}
            </div>
            {!isEnoughTokens && (
                <div className="confirm-error">
                    Ooops. You don't have enough {CURRENCY_TYPE[tokenName]} to
                    buy this item
                </div>
            )}

            <Button
                className="confirm-submit"
                type="submit"
                size="l"
                onClick={handleSubmit}
                disabled={!isEnoughTokens}
            >
                Confirm
            </Button>
            <CancelButton />
        </div>
    );
}
