import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useApp } from '../../Data/AppContext';
import { useUser } from '../../Hooks/useUser';
import {
    fetchStakedItems,
    fetchTransactionHistory,
    stakeItems,
} from '../../Services';
import { Button } from '../Ui/v2/Button';

const StakingModal = ({ hidePopupHandler }) => {
    const activeUser = useUser();
    const { stakingConfig, miningMultiplier } = useApp();

    const {
        setTransactionInfo,
        setUserStakedItems,
        selectedItemsForStake,
        setSelectedItemsForStake,
        removeItemsFromUserNotStakedItems,
        preLaunchFinishTimestamp,
    } = useApp();

    const reward = selectedItemsForStake.reduce(
        (total, { miningInfo: { dailyPool } }) => total + dailyPool,
        0
    );

    const handleStake = useCallback(() => {
        stakeItems({
            activeUser,
            items: selectedItemsForStake.map(({ asset_id }) => asset_id),
        })
            .then(() => {
                fetchStakedItems({
                    account: activeUser.accountName,
                    miningMultiplier,
                    stakingConfig,
                    preLaunchFinishTimestamp,
                }).then((items) => setUserStakedItems(items));

                fetchTransactionHistory({
                    account: activeUser.accountName,
                }).then((items) => setTransactionInfo(items));

                removeItemsFromUserNotStakedItems(selectedItemsForStake);
                setSelectedItemsForStake([]);
                hidePopupHandler();

                toast.success('Staked');
            })
            .catch((e) => toast.error(e.message));
    }, []);

    return (
        <div className="content_block staking_modal">
            <div className="content_block">
                <div className="title_block">Staking confirmation</div>
                <div className="time_container">
                    <div className="title">Staking pool</div>
                    <div>
                        <span>Daily Pool</span>
                    </div>
                </div>
                <div className="staking_list">
                    <div className="item">
                        <div className="title">NFTs selected</div>
                        <div className="value">
                            {selectedItemsForStake.length}
                        </div>
                    </div>
                    <div className="item">
                        <div className="title">Daily reward</div>
                        <div className="value">
                            {`${reward.toFixed(4)} vDMP`}
                        </div>
                    </div>
                </div>
                <div className="button_blocks">
                    <Button size="l" onClick={handleStake}>
                        Stake
                    </Button>
                    <Button size="l" use="secondary" onClick={hidePopupHandler}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StakingModal;
