import React, { useState } from 'react';
import FaqItem from './FaqItem';

const FaqList = () => {
    const [active, setActive] = useState(false);

    const questions = [
        {
            id: 0,
            question: 'What is DeepMine Staking Shelter?',
            answer: 'DeepMine is a modern economic strategy. The sophisticated economic system includes various DeFi mechanics, some of which are available right now. DeepMine Staking Shelter allows you to stake the upcoming game assets even before the gameplay begins to receive DMP tokens.',
            type: 'text',
        },
        {
            id: 1,
            question: 'What can I do in the Shelter?',
            answer: [
                {
                    title: null,
                    list: [
                        {
                            id: 0,
                            text: 'Stake NFTs to receive DMP (DeepMinePower, which is the DeepMine metaverse governance token).',
                        },
                        {
                            id: 1,
                            text: 'Participate in auctions for areas NFTs for DMP and WAX (areas are valuable DeepMine NFTs. Shelter auctions will be released in Q1 2022).',
                        },
                    ],
                },
            ],
            type: 'list',
        },
        {
            id: 2,
            question: 'What is DMP?',
            answer: 'DeepMine power is the in-game native governance token on WAX blockchain. Token will be launched in the near future. As an asset, DMP is a long-term store of value that influences DeepMine politics and economics.',
            type: 'text',
        },
        {
            id: 3,
            question: 'What can I do with DMP?',
            answer: [
                {
                    title: 'DMP in-game roles:',
                    list: [
                        {
                            id: 0,
                            text: 'DMP is the defining factor of DAO Power in the DeepMine metaverse.',
                        },
                        {
                            id: 1,
                            text: 'DMP can be staked to vote for a Senator (Senator is a game role that influences the DeepMine politics).',
                        },
                        {
                            id: 2,
                            text: 'DMP is required to vote on the distribution of game funds and influence the lore.',
                        },
                        {
                            id: 3,
                            text: 'DMP is a must to create a guild that unites players around an area.',
                        },
                        {
                            id: 4,
                            text: "DMP is a prerequisite for the formation of a political party and must be staked in the party's fund.",
                        },
                        {
                            id: 5,
                            text: 'DMP is needed for a unique and costly process of upgrading areas (valuable DeepMine NFTs) by rarity.',
                        },
                        {
                            id: 6,
                            text: 'DMP is used to craft unique temporary boosts needed by multiple game roles.',
                        },
                    ],
                },
                {
                    title: 'DMP meta-game role:',
                    list: [
                        {
                            id: 0,
                            text: 'DMP can be traded on exchanges.',
                        },
                        {
                            id: 1,
                            text: 'DMP can be used to buy areas during special DMP auctions.',
                        },
                        {
                            id: 2,
                            text: 'DMP can be used to increase the chances of getting into the whitelist during the next drops or even purchase the whitelist pass.',
                        },
                    ],
                },
            ],
            type: 'list',
        },
        {
            id: 4,
            question: 'Why do I see vDMP in the Shelter?',
            answer: 'Your assets can earn you DMP even before the launch of the token. To make it possible, we introduced vDMP (virtual DMP) which represents the number of DMP tokens you will receive after the token generation event. 1 vDMP = 1 DMP.',
            type: 'text',
        },
        {
            id: 5,
            question: 'How to stake?',
            answer: [
                {
                    title: null,
                    list: [
                        {
                            id: 0,
                            text: "Acquire DeepMine NFTs (areas or equipment) on <a href='https://wax.atomichub.io/market?collection_name=deepminegame&order=desc&sort=created&symbol=WAX'>AtomicHub</a> or any other way.",
                        },
                        {
                            id: 1,
                            text: 'Connect your WAX wallet to DeepMine Staking Shelter.',
                        },
                        {
                            id: 2,
                            text: 'Go to the NFT Staking menu and pick NFTs you wish to stake.',
                        },
                    ],
                },
            ],
            type: 'list',
            numeric: true,
        },
        {
            id: 6,
            question: 'Staking terms?',
            answer: [
                {
                    title: 'Daily Pool:',
                    list: [
                        {
                            id: 0,
                            text: 'Short-term staking.',
                        },
                        {
                            id: 1,
                            text: 'DMP rewards calculated every 24 hours.',
                        },
                        {
                            id: 2,
                            text: 'Unstake after 24 hours.',
                        },
                        {
                            id: 3,
                            text: 'Normal reward rate.',
                        },
                    ],
                },
                {
                    title: 'Pre-launch Pool:',
                    list: [
                        {
                            id: 0,
                            text: 'Long-term stake until May 15 2022.',
                        },
                        {
                            id: 1,
                            text: 'DMP rewards calculated every 24 hours.',
                        },
                        {
                            id: 2,
                            text: 'Unstake only on May 15th 2022 or later.',
                        },
                        {
                            id: 3,
                            text: 'Multiplied reward rate.',
                        },
                        {
                            id: 4,
                            text: 'DMP claimable at any time.',
                        },
                    ],
                },
            ],
            type: 'list',
        },
        {
            id: 7,
            question: 'How does staking reward calculation work?',
            answer: [
                {
                    title: 'Pre-launch Pool',
                    tables: [
                        {
                            id: 0,
                            table_title: 'AREA',
                            list: ['50', '100', '200', '300', '500'],
                        },
                        {
                            id: 1,
                            table_title: 'EQUIPMENT',
                            list: [
                                '0.0132',
                                '0.0432',
                                '0.1404',
                                '0.4536',
                                '1.4580',
                            ],
                        },
                    ],
                },
                {
                    title: 'Daily Pool',
                    tables: [
                        {
                            id: 0,
                            table_title: 'AREA',
                            list: ['12.5', '25', '50', '75', '125'],
                        },
                        {
                            id: 1,
                            table_title: 'EQUIPMENT',
                            list: [
                                '0.0033',
                                '0.0108',
                                '0.0351',
                                '0.1134',
                                '0.3645',
                            ],
                        },
                    ],
                },
            ],
            note: 'Note: Calculations reward table could be modified and updated during the stake period in order to balance the game economy.',
            type: 'table',
        },
        {
            id: 8,
            question:
                "Why can't I see my assets or operations log immediately after transactions?",
            answer: 'It takes time to update information from the blockchain. Wait a couple of minutes then refresh a page.',
            type: 'text',
        },
        {
            id: 9,
            question: 'What is Auction?',
            answer: 'DeepMine auction allows you to acquire DM Metaverse assets. The bidders compete against each other, with each subsequent bid being higher than the previous bid.',
            type: 'text',
        },
        {
            id: 10,
            question: 'What do I need to participate in an auction?',
            answer: "Log into the Shelter and make sure you've got enough funds in your WAX Wallet.",
            type: 'text',
        },
        {
            id: 11,
            question: 'What currencies are accepted?',
            answer: 'DMP (vDMP) & WAX are accepted',
            type: 'text',
        },
        {
            id: 12,
            question: 'What is the starting bid?',
            answer: '“Starting bid” (also called “opening bid”): the amount needed to open bidding and activate an auction.',
            type: 'text',
        },
        {
            id: 13,
            question: 'What is the current bid?',
            answer: '“Current bid” is the last actual bid that the buyer made. To increase the chances of winning, your bid must be greater than the current bid.',
            type: 'text',
        },
        {
            id: 14,
            question: "I'm in an auction, can I cancel my bid?",
            answer: 'No, the bid cannot be canceled until the end of the auction.',
            type: 'text',
        },
        {
            id: 15,
            question:
                "I'm in an auction, what happens if someone else wins it?",
            answer: 'Your bid will be returned to you within a few minutes.',
            type: 'text',
        },
        {
            id: 16,
            question: 'How many auctions can I participate in?',
            answer: "There is no limit. Participate in as many auctions as you want (given you've got enough funds of course).",
            type: 'text',
        },
        {
            id: 17,
            question: 'What is Drop?',
            answer: 'Drop is a place where special NFT releases (usually limited in time and stock) take place, usually dedicated to a game event or development stage.',
            type: 'text',
        },
        {
            id: 18,
            question: 'What is the difference between a Drop and Store?',
            answer: "Unlike Store, Drop usually features limits on the amount of items one user can buy, is limited in time and may require a user's wallet to be in the whitelist.",
            type: 'text',
        },
        {
            id: 19,
            question:
                'What is the difference between Whitelist and Public (Sale & Drop)?',
            answer: 'The Whitelist part of any event is only for people who were included in the whitelist by meeting certain requirements. The Public part of the event allows everyone to participate.',
            type: 'text',
        },
        {
            id: 20,
            question: 'What currencies can I use to acquire assets?',
            answer: 'You can acquire items either for DMP (vDMP) or WAX.',
            type: 'text',
        },
        {
            id: 21,
            question: 'What is Cooldown?',
            answer: '"Cooldown" is the time you need to wait before you are eligible to purchase another assets of the same kind.',
            type: 'text',
        },
        {
            id: 22,
            question: 'How do I join the whitelist?',
            answer: (
                <div>
                    Follow our{' '}
                    <a href="https://twitter.com/DeepMineWorld">Twitter</a>,{' '}
                    <a href="https://medium.com/@deepmineworld">Medium</a>, and
                    join{' '}
                    <a href="https://discord.com/invite/2gUMMerVVq">Discord</a>{' '}
                    and <a href="https://t.me/DeepMineWorld">Telegram</a> groups
                    to stay up to date with new events and events and find out
                    the whitelist requirements.
                </div>
            ),
            type: 'text',
        },
    ];

    return (
        <div className="faq_list">
            <div className="faq__container">
                <div className="faq_list_wrap">
                    {questions.map((question) => (
                        <FaqItem
                            key={question.id}
                            item={question}
                            active={active}
                            setActive={setActive}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FaqList;
