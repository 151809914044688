import { useRef } from 'react';
import { useQuery } from 'react-query';
import { getHistory, getHistoryTransfer } from './services';
import { ENDPOINTS } from '../../../Helpers';

const useTransactionHistory = (assetId) => {
    let { current: endpoint } = useRef(ENDPOINTS[1]);

    const history = useQuery(
        ['auction-history', assetId],
        () => getHistory(assetId, 10, endpoint),
        {
            retryDelay: 1500,
            select: ({ data }) => data.actions,
            refetchInterval: 10000,
            refetchIntervalInBackground: true,
            retry: (failureCount) => {
                const requestNumber = failureCount + 1;
                const needRetry = requestNumber <= ENDPOINTS.length;

                if (needRetry) {
                    endpoint = ENDPOINTS[requestNumber];
                }

                return needRetry;
            },
        }
    );

    return history;
};

const useHistoryTransfer = (assetId) => {
    let { current: endpoint } = useRef(ENDPOINTS[1]);

    const transfers = useQuery(
        ['auction-history-transfer', assetId],
        () => getHistoryTransfer(100, endpoint),
        {
            retryDelay: 1500,
            select: ({ data }) => {
                return data.actions
                    ?.filter(
                        (item) =>
                            +(
                                item.act.data?.memo ??
                                item.act.data?.data?.memo ??
                                0
                            ) === +assetId
                    )
                    .reduce((acc, item) => {
                        const data = item.act.data?.data ?? item.act.data;
                        const symbol = data.quantity.split(' ')[1];
                        const transfer = {
                            timestamp: item.timestamp,
                            trx_id: item.trx_id,
                            act: {
                                data: {
                                    ...data,
                                    symbol,
                                },
                            },
                        };
                        return [...acc, transfer];
                    }, []);
            },
            refetchInterval: 10000,
            refetchIntervalInBackground: true,
            retry: (failureCount) => {
                const requestNumber = failureCount + 1;
                const needRetry = requestNumber <= ENDPOINTS.length;

                if (needRetry) {
                    endpoint = ENDPOINTS[requestNumber];
                }

                return needRetry;
            },
        }
    );

    return transfers;
};

export { useTransactionHistory, useHistoryTransfer };
