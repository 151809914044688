import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';

import classnames from 'classnames';
import { useApp } from '../../Data/AppContext';
import { STAKING_DURATION_1_DAY } from '../../Constants';
import { countTotalMiningReward, getImagePath } from '../../Helpers';
import { Button } from '../Ui/v2/Button';
import { CardCheckBox } from './CardCheckBox';

const CardItem = ({ type, item, selectCard, selectedCards }) => {
    const {
        showPopupHandler,
        preLaunchFinishTimestamp,
        setSelectedItemsForStake,
        setSelectedItemsForUnstake,
    } = useApp();

    const startDay = new Date().setHours(0, 0, 0, 0);
    const [timeLeftForDailyReward, setTimeLeftForDailyReward] = useState(
        item.stakingInfo
            ? item.stakingInfo.time * 1000 + 60 * 60 * 24 * 1000
            : 0
    );

    useEffect(() => {
        if (new Date().getTime() > timeLeftForDailyReward) {
            const todaySeconds = new Date().getTime() / 1000;
            const lastClaimTimeSeconds = timeLeftForDailyReward / 1000;

            const daysDifference =
                todaySeconds / 86400 - lastClaimTimeSeconds / 86400;
            const nextDay = Math.ceil(daysDifference);

            const daysLeftToReward = nextDay - daysDifference;

            setTimeLeftForDailyReward(
                new Date().getTime() + daysLeftToReward * 24 * 60 * 60 * 1000
            );
        }
    }, [timeLeftForDailyReward]);

    function countdownRenderer({ days, hours, minutes, seconds, completed }) {
        if (completed) return <>0d 0h 0m</>;

        if (days)
            return (
                <>
                    {days}d {hours}h {minutes}m
                </>
            );

        return (
            <>
                {hours}h {minutes}m {seconds}s
            </>
        );
    }

    const onDailyRewardComplete = () => {
        if (
            item.stakingInfo.stakingDuration === STAKING_DURATION_1_DAY ||
            Date.now() < preLaunchFinishTimestamp
        ) {
            setTimeLeftForDailyReward(Date.now() + 60 * 60 * 24 * 1000);
        }
    };

    const isSelected = selectedCards.some(
        ({ asset_id }) => asset_id === item.asset_id
    );

    if (type === 'nft') {
        return (
            <div
                onClick={() => {
                    selectCard(item);
                }}
                className={classnames('card-item', { select: isSelected })}
            >
                <CardCheckBox isChecked={isSelected} />
                <div className="card-item__img">
                    <img
                        loading="lazy"
                        src={getImagePath(Number(item.template.template_id))}
                        alt={item.data.name}
                    />
                </div>

                <div className="select_element">
                    Click and select <br /> for staking info
                </div>

                <div className={classnames('unstaked', { select: isSelected })}>
                    {!item.stakingInfo ? (
                        <div className="wrapper_stake">
                            <div className="title">
                                vDMP Daily <br /> Reward
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="text">Daily pool:</div>
                                    <div className="value">
                                        {item.miningInfo &&
                                        item.miningInfo.dailyPool
                                            ? item.miningInfo.dailyPool.toFixed(
                                                  4
                                              )
                                            : 0}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text">Pre-launch pool:</div>
                                    <div className="value">
                                        {item.miningInfo &&
                                        item.miningInfo.preLaunchPool
                                            ? item.miningInfo.preLaunchPool.toFixed(
                                                  4
                                              )
                                            : 0}
                                    </div>
                                </div>
                            </div>
                            <div className="btn_container">
                                <Button
                                    use="secondary"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedItemsForStake([item]);
                                        showPopupHandler('staking');
                                    }}
                                >
                                    Stake
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="wrapper_stake">
                            <div className="title">Collect NFTs?</div>
                            <div className="list">
                                <div className="item">
                                    <div className="text">
                                        Time left to unstake:
                                    </div>
                                    <div className="value">
                                        {item.stakingInfo.stakingDuration ===
                                        STAKING_DURATION_1_DAY ? (
                                            <Countdown
                                                date={
                                                    new Date(
                                                        item.stakingInfo
                                                            .initial_time_stake *
                                                            1000 +
                                                            60 * 60 * 24 * 1000
                                                    )
                                                }
                                                renderer={countdownRenderer}
                                            />
                                        ) : (
                                            <Countdown
                                                date={preLaunchFinishTimestamp}
                                                renderer={countdownRenderer}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text">
                                        Time left for daily reward:
                                    </div>
                                    <div className="value">
                                        <Countdown
                                            date={
                                                item.stakingInfo
                                                    .stakingDuration ===
                                                STAKING_DURATION_1_DAY
                                                    ? new Date(
                                                          timeLeftForDailyReward
                                                      )
                                                    : new Date(
                                                          startDay >=
                                                          preLaunchFinishTimestamp
                                                              ? preLaunchFinishTimestamp
                                                              : timeLeftForDailyReward
                                                      )
                                            }
                                            renderer={countdownRenderer}
                                            onComplete={onDailyRewardComplete}
                                        />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text">
                                        vDMP total reward:
                                    </div>
                                    <div className="value">
                                        {countTotalMiningReward({
                                            preLaunchFinishTimestamp,
                                            stakingDuration:
                                                item.stakingInfo
                                                    .stakingDuration,
                                            startTimestamp:
                                                item.stakingInfo
                                                    .initial_time_stake,
                                            rewardPerDay: item.stakingInfo.rate,
                                        }).toFixed(4)}
                                    </div>
                                </div>
                            </div>
                            <div className="btn_container">
                                {/* todo: disable if cant unstake */}
                                <Button
                                    disabled={false}
                                    use="secondary"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedItemsForUnstake([item]);
                                        showPopupHandler('unstake');
                                    }}
                                >
                                    Unstake
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            onClick={() => selectCard(item)}
            className={classnames('card-item', { select: isSelected })}
        >
            <CardCheckBox isChecked={isSelected} />
            <div className="card-item__img">
                <img
                    src="https://ipfs.atomichub.io/ipfs/QmRsP3ACHpWXLXnNDejb2qnjCa1YdeuStz98c5HoVjdyfa"
                    alt="Card"
                />
            </div>

            <div className={classnames('unstaked', { select: isSelected })}>
                {!item.stakingInfo ? (
                    <div className="wrapper_stake">
                        <div className="title">Collect NFT?</div>
                        <div className="list">
                            <div className="item">
                                <div className="text">vDMP Cost</div>
                                <div className="value">
                                    {/* TODO: remove hardcode (DMP Cost ?) */}
                                    1231
                                </div>
                            </div>
                            <div className="item">
                                <div className="text">Staking Value</div>
                                <div className="value">
                                    {/* TODO: remove hardcode (Staking Value ?) */}
                                    1231
                                </div>
                            </div>
                        </div>
                        <div className="btn_container">
                            <Button
                                use="secondary"
                                onCLick={() => showPopupHandler('claim')}
                            >
                                Collect
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="wrapper_stake">
                        <div className="title">Collect NFT?</div>
                        <div className="list">
                            <div className="item">
                                <div className="text">
                                    {/* TODO: remove hardcode (DMP Cost ?) */}
                                    1231
                                </div>
                                <div className="value">1231</div>
                            </div>
                            <div className="item">
                                <div className="text">Staking Value</div>
                                <div className="value">
                                    {/* TODO: remove hardcode (Staking Value ?) */}
                                    1231
                                </div>
                            </div>
                        </div>
                        <div className="btn_container">
                            <Button
                                use="secondary"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    showPopupHandler('claim');
                                }}
                            >
                                Collect
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardItem;
