import { getMs } from './utils';
import { STAKING_DURATION_1_DAY } from '../Constants';

export const countDaysDifference = (
    startTimestamp,
    stakingDuration,
    preLaunchFinishTimestamp
) => {
    const oneDay = 8.64e7;
    const isDailyPool = stakingDuration === STAKING_DURATION_1_DAY;
    const startDate = new Date(getMs(startTimestamp)).getTime();
    const endDate = isDailyPool ? Date.now() : preLaunchFinishTimestamp;

    const totalDays = Math.floor((endDate - startDate + 1) / oneDay);

    return totalDays;
};

export const countDmpToClaim = ({ stakedItems, preLaunchFinishTimestamp }) => {
    let dmpToClaim = 0;

    if (!stakedItems || !stakedItems.length) return dmpToClaim.toFixed(4);

    stakedItems.forEach(({ stakingInfo }) => {
        if (stakingInfo) {
            const totalDays = countDaysDifference(
                stakingInfo.time,
                stakingInfo.stakingDuration,
                preLaunchFinishTimestamp
            );
            dmpToClaim +=
                totalDays <= 0 ? 0 : totalDays * Number(stakingInfo.rate);
        }
    });
    return dmpToClaim.toFixed(4);
};

export const countTotalMiningReward = ({
    preLaunchFinishTimestamp,
    stakingDuration,
    startTimestamp,
    rewardPerDay,
}) => {
    const totalDays = countDaysDifference(
        startTimestamp,
        stakingDuration,
        preLaunchFinishTimestamp
    );

    return totalDays <= 0 ? 0 : totalDays * Number(rewardPerDay);
};

export const countTotalMiningRewardForHours = ({
    startTimestamp,
    rewardPerDay,
}) => {
    const startDate = new Date(getMs(startTimestamp)).getTime();
    const now = Date.now();

    const totalHours = Math.floor((now - startDate) / 36e5);

    return totalHours <= 0 ? 0 : (totalHours * Number(rewardPerDay)) / 24;
};

export const countTotalUserReward = (
    dmpBalance,
    stakedList,
    preLaunchFinishTimestamp
) => {
    const collected = dmpBalance
        ? Number(dmpBalance.substr(0, dmpBalance.lastIndexOf(' ')))
        : 0;

    return (
        collected +
        Number(
            countDmpToClaim({
                stakedItems: stakedList,
                preLaunchFinishTimestamp,
            })
        )
    );
};
