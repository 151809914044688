import React from 'react';
import classnames from 'classnames';

import { WAX_EXPLORER_LINK } from '../../Constants';

const TransactionItem = ({ id, type, date, amount, status, txId }) => {
    const transactionHistoryBloks = () => {
        window.open(`${WAX_EXPLORER_LINK}/${txId}`, '_blank');
    };

    return (
        <tr className="transaction_table__line">
            <td className="table_id">{id}</td>
            <td>{type}</td>
            <td>{date}</td>
            <td style={{ opacity: amount ? 1 : 0 }}>
                {amount && type !== 'Collect'
                    ? `${amount} NFT`
                    : `${amount} vDMP`}
            </td>
            <td
                className={classnames({
                    success: status === 'Successful',
                    error: status === 'Failed',
                    progress: status === 'In progress',
                })}
            >
                {status}
            </td>
            <td onClick={transactionHistoryBloks} className="bloks-history">
                {txId.slice(0, 8)}
            </td>
        </tr>
    );
};

export default TransactionItem;
