import { useMutation, useQueryClient } from 'react-query';
import { signTransaction } from '../../../Helpers';
import { useUser } from '../../../Hooks/useUser';
import { useUpdateBalance } from '../../../Hooks/useUpdateBalance';
import { CONTRACT_NAMES, TOKEN_TYPE } from '../../../Constants';

const getVDmpBidConfig = (assetId, quantity, activeUser) => {
    return {
        activeUser,
        account: CONTRACT_NAMES.auction,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_NAMES.auction,
            quantity: `${quantity.toFixed(8)} DMP`,
            memo: `${assetId}`,
        },
    };
};

const getWaxBidConfig = (assetId, quantity, activeUser) => ({
    activeUser,
    account: CONTRACT_NAMES.eosioToken,
    action: 'transfer',
    data: {
        from: activeUser.accountName,
        to: CONTRACT_NAMES.auction,
        quantity: `${quantity.toFixed(8)} WAX`,
        memo: `${assetId}`,
    },
});

const useTransaction = (assetId, priceType, accountName) => {
    const activeUser = useUser();
    const client = useQueryClient();
    const updateUserBalance = useUpdateBalance();

    const mutation = useMutation(
        ({ quantity }) => {
            return +priceType === TOKEN_TYPE.DMP
                ? signTransaction(
                      getVDmpBidConfig(assetId, quantity, activeUser)
                  )
                : signTransaction(
                      getWaxBidConfig(assetId, quantity, activeUser)
                  );
        },
        {
            async onMutate() {
                const prevAuctions = await client.getQueryData([
                    'auction',
                    assetId,
                ]);
                await client.setQueryData(
                    ['auction', assetId],
                    (prevAuctions) => {
                        const newData = prevAuctions.rows.map((auction) => {
                            if (auction.asset_id === assetId) {
                                return {
                                    ...auction,
                                    current_winner: accountName,
                                };
                            }

                            return auction;
                        });
                        return {
                            ...prevAuctions,
                            rows: newData,
                        };
                    }
                );

                return () => client.setQueryData('auction', prevAuctions);
            },
            onError: (_, __, rollback) => rollback(),
            onSettled: () => client.invalidateQueries(['auction', assetId]),
            onSuccess: () => updateUserBalance(),
        }
    );

    return mutation;
};

export { useTransaction };
