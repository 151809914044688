import React from 'react';
import { Button } from '../Ui/v2/Button';

const DMPModal = () => {
    return (
        <div className="content_block DMP_modal">
            <div className="text">
                Please, change your choice or earn more vDMP
            </div>
            <div className="button_blocks">
                <Button>Ok</Button>
            </div>
        </div>
    );
};

export default DMPModal;
