import {
    CURRENCIES,
    CURRENCY_TYPE,
    RARITY_ITEM_TYPES,
    TOKEN_TYPE,
} from '../../Constants';

export const coinOptions = [
    { value: 'all', name: 'All' },
    { value: TOKEN_TYPE.DMP, name: CURRENCY_TYPE[CURRENCIES.vDMP] },
    { value: TOKEN_TYPE.WAX, name: CURRENCY_TYPE[CURRENCIES.WAX] },
];

export const rarityOptions = RARITY_ITEM_TYPES.slice(1).map((rarity) => ({
    value: rarity.toLowerCase(),
    name: rarity,
}));

export const LOT_TYPE = {
    Store: 0,
    Drop: 1,
    Unknown: 2,
};

export const DROP_TYPE = {
    Whitelist: 0,
    Public: 1,
    All: 2,
    Unknown: 3,
};
