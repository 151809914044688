import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as DmpIcon } from '../../assets/img/icons/dmp_icon.svg';
import { useApp } from '../../Data/AppContext';

import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';

import { COMMON, EPIC, LEGENDARY, RARE, UNCOMMON } from '../../Constants';
import { countDmpToClaim } from '../../Helpers';

const StakingModal = () => {
    const {
        stakedList,
        unstakedList,
        stakingPopup,
        toggleStakingPopupHandler,
        preLaunchFinishTimestamp,
    } = useApp();

    const [showInformation, setShowInformation] = useState(null);
    const [showFaq, setShowFaq] = useState(false);
    const [dmpToStake, setDmpToStake] = useState('0.0000');

    useEffect(() => {
        setDmpToStake(
            countDmpToClaim({
                stakedItems: stakedList,
                preLaunchFinishTimestamp,
            })
        );
    }, [stakedList, preLaunchFinishTimestamp]);

    const [totalDmpReward, setTotalDmpReward] = useState(0);
    const [dailyDmpReward, setDailyDmpReward] = useState(0);

    const [stakedItemsInfo, setStakedItemsInfo] = useState({
        common: 0,
        uncommon: 0,
        rare: 0,
        epic: 0,
        legendary: 0,
    });
    const [unstakedItemsInfo, setUnstakedItemsInfo] = useState({
        common: 0,
        uncommon: 0,
        rare: 0,
        epic: 0,
        legendary: 0,
    });

    const countItemsByRarity = (items, rarityToFilter) => {
        return items.filter(
            ({ data: { rarity } }) =>
                rarity.toLowerCase() === rarityToFilter.toLowerCase()
        ).length;
    };

    useEffect(() => {
        setStakedItemsInfo({
            common: countItemsByRarity(stakedList, COMMON),
            uncommon: countItemsByRarity(stakedList, UNCOMMON),
            rare: countItemsByRarity(stakedList, RARE),
            epic: countItemsByRarity(stakedList, EPIC),
            legendary: countItemsByRarity(stakedList, LEGENDARY),
        });

        setDailyDmpReward(
            stakedList.reduce(
                (total, { stakingInfo: { rate } }) => total + Number(rate),
                0
            )
        );
        setTotalDmpReward(
            stakedList.reduce(
                (total, { miningInfo: { totalMiningReward } }) =>
                    total + totalMiningReward,
                0
            )
        );
    }, [stakedList]);

    useEffect(() => {
        setUnstakedItemsInfo({
            common: countItemsByRarity(unstakedList, COMMON),
            uncommon: countItemsByRarity(unstakedList, UNCOMMON),
            rare: countItemsByRarity(unstakedList, RARE),
            epic: countItemsByRarity(unstakedList, EPIC),
            legendary: countItemsByRarity(unstakedList, LEGENDARY),
        });
    }, [unstakedList]);

    const showInfo = (value) => {
        if (showInformation !== null) setShowInformation(null);

        setShowFaq(value);
    };

    const activeItemHandler = (value) => {
        if (showInformation === value) return setShowInformation(null);

        showInfo(false);
        setShowInformation(value);
    };

    return (
        <div
            className={classnames('staking_popup', { active: stakingPopup })}
            onClick={() => showInfo(false)}
        >
            <div className="close_modal">
                <button onClick={() => toggleStakingPopupHandler(false)}>
                    Close <span>x</span>
                </button>
            </div>

            <div className="staking_container">
                <div className="staking_info">
                    <h3 className="block_title">Staking Info</h3>
                    <div className="block_information">
                        <div
                            className={classnames('information_item slide', {
                                show: showInformation === 'showInformation',
                            })}
                            onClick={() => activeItemHandler('showInformation')}
                        >
                            <div className="information_title">
                                Staked NFTs
                                <span
                                    className="info_block"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        showInfo('staked');
                                    }}
                                >
                                    <InfoIcon />
                                </span>
                                <div
                                    className={classnames('info_popup', {
                                        show: showFaq === 'staked',
                                    })}
                                >
                                    Text information about staked NFTs. Text
                                    information about staked NFTs. Text
                                    information about staked NFTs.
                                </div>
                            </div>
                            <div className="information_value information__info">
                                <div className="value">{stakedList.length}</div>
                            </div>
                            <div
                                className={classnames('information__content', {
                                    show: showInformation === 'showInformation',
                                })}
                            >
                                <div className="item">
                                    <div className="title">{COMMON}</div>
                                    <div className="value">
                                        {stakedItemsInfo.common}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{UNCOMMON}</div>
                                    <div className="value">
                                        {stakedItemsInfo.uncommon}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{RARE}</div>
                                    <div className="value">
                                        {stakedItemsInfo.rare}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{EPIC}</div>
                                    <div className="value">
                                        {stakedItemsInfo.epic}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{LEGENDARY}</div>
                                    <div className="value">
                                        {stakedItemsInfo.legendary}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={classnames('information_item slide', {
                                show: showInformation === 'Non-staked NFTs',
                            })}
                            onClick={() => activeItemHandler('Non-staked NFTs')}
                        >
                            <div className="information_title">
                                Non-staked NFTs
                                <span
                                    className="info_block"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        showInfo('unstaked');
                                    }}
                                >
                                    <InfoIcon />
                                </span>
                                <div
                                    className={classnames('info_popup', {
                                        show: showFaq === 'unstaked',
                                    })}
                                >
                                    Text information about staked NFTs. Text
                                    information about staked NFTs. Text
                                    information about staked NFTs.
                                </div>
                            </div>
                            <div className="information_value information__info">
                                <div className="value">
                                    {unstakedList.length}
                                </div>
                            </div>
                            <div
                                className={classnames('information__content', {
                                    show: showInformation === 'Non-staked NFTs',
                                })}
                            >
                                <div className="item">
                                    <div className="title">{COMMON}</div>
                                    <div className="value">
                                        {unstakedItemsInfo.common}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{UNCOMMON}</div>
                                    <div className="value">
                                        {unstakedItemsInfo.uncommon}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{RARE}</div>
                                    <div className="value">
                                        {unstakedItemsInfo.rare}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{EPIC}</div>
                                    <div className="value">
                                        {unstakedItemsInfo.epic}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="title">{LEGENDARY}</div>
                                    <div className="value">
                                        {unstakedItemsInfo.legendary}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="information_item">
                            <div className="information_title">
                                vDMP daily reward
                                <span
                                    className="info_block"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        showInfo('dmp-daily');
                                    }}
                                >
                                    <InfoIcon />
                                </span>
                                <div
                                    className={classnames('info_popup', {
                                        show: showFaq === 'dmp-daily',
                                    })}
                                >
                                    Amount of vDMP you receive daily with your
                                    current stake.
                                </div>
                            </div>
                            <div className="information_value">
                                <div className="value">
                                    <DmpIcon className="icon" />{' '}
                                    {dailyDmpReward.toFixed(4)}
                                </div>
                            </div>
                        </div>

                        <div className="information_item">
                            <div className="information_title">
                                vDMP Total Reward
                                <span
                                    className="info_block"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        showInfo('dmp');
                                    }}
                                >
                                    <InfoIcon />
                                </span>
                                <div
                                    className={classnames('info_popup', {
                                        show: showFaq === 'dmp',
                                    })}
                                >
                                    Total vDMP collected from the Staking
                                    Shelter.
                                </div>
                            </div>
                            <div className="information_value">
                                <div className="value">
                                    <DmpIcon className="icon" />{' '}
                                    {totalDmpReward.toFixed(4)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="earned_rewards">
                    <div className="block_title">
                        Uncollected vDMP
                        <span
                            className="info_icon"
                            onClick={(event) => {
                                event.stopPropagation();
                                showInfo('unclaimed');
                            }}
                        >
                            <InfoIcon />
                        </span>
                        <div
                            className={classnames('info_popup', {
                                show: showFaq === 'unclaimed',
                            })}
                        >
                            vDMP rewards since the last collect.
                        </div>
                    </div>
                    <div className="block_information">
                        <div className="information_item">
                            <div className="information_value">
                                <div className="value">{dmpToStake}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakingModal;
