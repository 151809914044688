import React from 'react';

import { RarityMarker } from '../../../../Components/Ui/v2';
import {
    CURRENCY_TYPE,
    rarityMap,
    TOKEN_TYPE,
    CURRENCIES,
} from '../../../../Constants';
import { ReactComponent as InfinityIcon } from '../../../../assets/img/icons/infinity.svg';
import { ReactComponent as DmpIcon } from '../../../../assets/img/icons/dmp_icon.svg';
import { ReactComponent as WaxIcon } from '../../../../assets/img/icons/wax_icon_orange.svg';
import { formatNumber, getBalanceCount } from '../../../../Helpers';

const OptionsCell = ({ title, content }) => (
    <div className="store-product-page-options-cell">
        <p>{title}</p>
        <span>{content}</span>
    </div>
);

const ICONS = {
    [TOKEN_TYPE.DMP]: DmpIcon,
    [TOKEN_TYPE.WAX]: WaxIcon,
};

export const Info = ({ card }) => {
    const priceType =
        card.price_type === TOKEN_TYPE.DMP ? CURRENCIES.vDMP : CURRENCIES.WAX;
    const CurrencyIcon = ICONS[card.price_type];

    const currency = CURRENCY_TYPE[priceType];
    const itemsLeft = card?.asset_ids.length ?? 0;
    const itemsLeftTotal = card.initial_items_count;

    return (
        <div className="store-product-page-options-grid">
            <OptionsCell title="Template ID" content={`#${card.template_id}`} />
            {card.rarity ? (
                <OptionsCell
                    title="Rarity"
                    content={
                        <>
                            <RarityMarker value={card.rarity} />{' '}
                            {rarityMap[card.rarity]}
                        </>
                    }
                />
            ) : (
                <div className="store-product-page-options-cell" />
            )}
            {card.template_mint !== '' && card.total_template_mints !== '' && (
                <OptionsCell
                    title="Mint number"
                    content={
                        <>
                            {card.template_mint}
                            {' of '}
                            {card.total_template_mints} (max:{' '}
                            {+card.max_supply_mint || <InfinityIcon />})
                        </>
                    }
                />
            )}
            <OptionsCell
                title="Price"
                content={
                    <div className="store-product-page-options__price-block">
                        <CurrencyIcon width={16} height={16} />
                        <span
                            className={`store-product-page-options__price ${priceType}`}
                        >
                            {` ${formatNumber(
                                getBalanceCount(priceType, card.price_per_asset)
                            )} ${currency}`}
                        </span>
                    </div>
                }
            />
            <OptionsCell
                title="Items left"
                content={`${itemsLeft} of ${itemsLeftTotal}`}
            />
        </div>
    );
};
