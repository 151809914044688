export const UNSTAKE_PAGE = 'UNSTAKE_PAGE';
export const STAKE_PAGE = 'STAKE_PAGE';

export const STAKING_TABS = [
    {
        name: 'Staked NFTs',
        value: UNSTAKE_PAGE,
    },
    {
        name: 'Non-staked NFTs',
        value: STAKE_PAGE,
    },
];
