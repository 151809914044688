import { isMainNet } from '../Config';

export const defaultEndpoints = {
    wax: isMainNet
        ? [
              'https://api.waxsweden.org',
              'https://api.wax.greeneosio.com',
              'https://wax.blokcrafters.io',
              'https://wax.greymass.com',
              'https://wax.dapplica.io',
              'https://wax.cryptolions.io',
              'https://wax.pink.gg',
          ]
        : [
              'https://waxtestnet.greymass.com',
              'https://wax-test.blokcrafters.io',
              'https://testnet-wax.3dkrender.com',
              'https://testnet.wax.blacklusion.io',
              'https://wax-testnet.eosphere.io',
              'https://api-testnet-wax.eosarabia.net',
              'https://testnet.wax.pink.gg',
          ],
    atomic: isMainNet
        ? [
              // 'https://wax.api.atomicassets.io/atomicassets/v1',
              'https://wax.blokcrafters.io/atomicassets/v1',
              'https://aa.dapplica.io/atomicassets/v1',

              'https://wax.api.at1omicassets.io/atomicassets/v1',
              'https://wax.hkeos.com/aa/atomicassets/v1',
              'https://atomic2.hivebp.io/atomicassets/v1',
              'https://aa.wax.blacklusion.io/atomicassets/v1',
              'https://api.wax-aa.bountyblok.io/atomicassets/v1',
              'https://wax-aa.eosdublin.io/atomicassets/v1',
              'https://atomic.wax.eosrio.io/atomicassets/v1',
              'https://atomic.hivebp.io/atomicassets/v1',
              'https://wax-aa.eu.eosamsterdam.net/atomicassets/v1',
              'https://atomic.ledgerwise.io/atomicassets/v1',
              'https://api.atomic.greeneosio.com/atomicassets/v1',
              'https://atomic.sentnl.io/atomicassets/v1',
              'https://api-wax-aa.eosarabia.net/atomicassets/v1',
              'https://aa-api-wax.eosauthority.com/atomicassets/v1',
              'https://atomic.tokengamer.io/atomicassets/v1',
              'https://wax-atomic.eosiomadrid.io/atomicassets/v1',
              'https://api.wax.liquidstudios.io/atomicassets/v1',
              'https://atomic.3dkrender.com/atomicassets/v1',
          ]
        : ['https://test.wax.api.atomicassets.io/atomicassets/v1'],
};

if (!window.ENDPOINTS) {
    window.ENDPOINTS = defaultEndpoints;
}
