import React from 'react';
import classnames from 'classnames';

import { useApp } from '../../../Data/AppContext';
import { ReactComponent as CancelIcon } from '../../../Containers/AffiliatePage/img/cancel.svg';

import './AffiliateModal.scss';
import { DiscordModal } from './DiscordModal/DiscordModal';
import { DiscordModalError } from './DiscordModalError/DiscordModalError';
import { AchievementModal } from './AchievementModal/AchievementModal';
import { EmailModal } from './EmailModal/EmailModal';
import { EmailModalError } from './EmailModalError/EmailModalError';
import { AffiliateModalAuthSuccess } from './AffiliateModalAuthSuccess/AffiliateModalAuthSuccess';
import { AffiliateModalAuthError } from './AffiliateModalAuthError/AffiliateModalAuthError';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { CallToAuthModal } from '../CallToAuthModal/CallToAuthModal';

const stopPropagation = (event) => event.stopPropagation();

export const AFFILIATION_PREFIX = 'affiliate';

const modals = {
    [`${AFFILIATION_PREFIX}-discord`]: () => <DiscordModal />,
    [`${AFFILIATION_PREFIX}-discord-error`]: () => <DiscordModalError />,
    [`${AFFILIATION_PREFIX}-achievement-recruit`]: () => (
        <AchievementModal type="recruit" />
    ),
    [`${AFFILIATION_PREFIX}-achievement-newcomer`]: () => (
        <AchievementModal type="newcomer" />
    ),
    [`${AFFILIATION_PREFIX}-achievement-motivator`]: () => (
        <AchievementModal type="motivator" />
    ),
    [`${AFFILIATION_PREFIX}-achievement-encourager`]: () => (
        <AchievementModal type="encourager" />
    ),
    [`${AFFILIATION_PREFIX}-achievement-inspirer`]: () => (
        <AchievementModal type="inspirer" />
    ),
    [`${AFFILIATION_PREFIX}-achievement-leader`]: () => (
        <AchievementModal type="leader" />
    ),
    [`${AFFILIATION_PREFIX}-email`]: () => <EmailModal />,
    [`${AFFILIATION_PREFIX}-confirm`]: () => <ConfirmModal />,
    [`${AFFILIATION_PREFIX}-email-error`]: () => <EmailModalError />,
    [`${AFFILIATION_PREFIX}-auth-success`]: () => <AffiliateModalAuthSuccess />,
    [`${AFFILIATION_PREFIX}-auth-error`]: () => <AffiliateModalAuthError />,
    [`${AFFILIATION_PREFIX}-call-to-auth`]: () => <CallToAuthModal />,
};

const AffiliateModal = () => {
    const { showPopup, popupType, hidePopupHandler } = useApp();

    if (!popupType?.startsWith(AFFILIATION_PREFIX)) {
        return null;
    }

    const renderModal = modals[popupType];

    return (
        <div
            className={classnames(popupType, 'affiliate-modal', {
                'affiliate-modal--active': showPopup,
            })}
            onClick={hidePopupHandler}
        >
            <div
                className="affiliate-modal__container"
                onClick={stopPropagation}
            >
                <div
                    className="affiliate-modal__close"
                    onClick={hidePopupHandler}
                >
                    <CancelIcon />
                </div>
                <div
                    className="affiliate-modal__content"
                    onClick={stopPropagation}
                >
                    {renderModal()}
                </div>
            </div>
        </div>
    );
};

export default AffiliateModal;
