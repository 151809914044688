import React, { useContext, useEffect, useState } from 'react';

import { UALContext } from 'ual-reactjs-renderer';
import { toast } from 'react-toastify';
import { useApp } from '../../Data/AppContext';

import { ReactComponent as DmpIcon } from '../../assets/img/icons/dmp_icon.svg';

import {
    claimMiningReward,
    fetchDmpBalance,
    fetchTransactionHistory,
} from '../../Services';
import { countDmpToClaim, countTotalMiningReward } from '../../Helpers';
import { Button } from '../Ui/v2/Button';

const ClaimModal = ({ hidePopupHandler }) => {
    const { activeUser } = useContext(UALContext);

    const {
        stakedList,
        setDmpBalance,
        setUserStakedItems,
        setTransactionInfo,
        preLaunchFinishTimestamp,
    } = useApp();

    const [dmpToClaim, setDmpToClaim] = useState(0);

    const handleClaim = () => {
        claimMiningReward({ activeUser })
            .then(() => {
                fetchDmpBalance({ account: activeUser.accountName })
                    .then((balance) => setDmpBalance(balance))
                    .catch((e) => console.log(e));

                fetchTransactionHistory({ account: activeUser.accountName })
                    .then((items) => setTransactionInfo(items))
                    .catch((e) => console.log(e));

                setUserStakedItems(
                    stakedList.map((item) => {
                        const time = new Date().getTime() / 1000;

                        return {
                            ...item,
                            stakingInfo: { ...item.stakingInfo, time },
                            miningInfo: {
                                ...item.miningInfo,
                                totalMiningReward: countTotalMiningReward({
                                    preLaunchFinishTimestamp,
                                    stakingDuration:
                                        item.stakingInfo.stakingDuration,
                                    startTimestamp: time,
                                    rewardPerDay: item.stakingInfo.rate,
                                }),
                            },
                        };
                    })
                );

                toast.success('Collected');
            })
            .catch((e) => toast.error(e.message))
            .finally(() => hidePopupHandler());
    };

    useEffect(() => {
        setDmpToClaim(
            countDmpToClaim({
                stakedItems: stakedList,
                preLaunchFinishTimestamp,
            })
        );
    }, [stakedList, preLaunchFinishTimestamp]);

    return (
        <div className="content_block claim_modal">
            <div className="content_block">
                <div className="title_block">Collect confirmation</div>
                <div className="content_wrapper-block">
                    <div className="name">vDMP to collect</div>
                    <div className="value">
                        <DmpIcon /> {dmpToClaim}
                    </div>
                </div>
                <div className="button_blocks">
                    <Button size="l" onClick={handleClaim}>
                        Collect
                    </Button>
                    <Button size="l" use="secondary" onClick={hidePopupHandler}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ClaimModal;
