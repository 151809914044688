import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useApp } from '../../Data/AppContext';

import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';

import { countTotalUserReward } from '../../Helpers';
import { COMMON, UNCOMMON, EPIC, RARE, LEGENDARY } from '../../Constants';
import { RarityMarker } from '../Ui/v2/RarityMarker';

const InfoBlock = () => {
    const [showInformation, setShowInformation] = useState(null);
    const [showFaq, setShowFaq] = useState(false);

    const activeItemHandler = (value) => {
        if (showInformation === value) return setShowInformation(null);

        setShowInformation(value);
    };

    const showInfo = (value) => {
        setShowFaq(value);
    };

    const { stakedList, unstakedList, dmpBalance, preLaunchFinishTimestamp } =
        useApp();

    const [totalDmpReward, setTotalDmpReward] = useState(0);
    const [dailyDmpReward, setDailyDmpReward] = useState(0);

    const [stakedItemsInfo, setStakedItemsInfo] = useState({
        common: 0,
        uncommon: 0,
        rare: 0,
        epic: 0,
        legendary: 0,
    });
    const [unstakedItemsInfo, setUnstakedItemsInfo] = useState({
        common: 0,
        uncommon: 0,
        rare: 0,
        epic: 0,
        legendary: 0,
    });

    const countItemsByRarity = (items, rarityToFilter) => {
        return items.filter(
            ({ data: { rarity } }) =>
                rarity.toLowerCase() === rarityToFilter.toLowerCase()
        ).length;
    };

    useEffect(() => {
        setStakedItemsInfo({
            common: countItemsByRarity(stakedList, COMMON),
            uncommon: countItemsByRarity(stakedList, UNCOMMON),
            rare: countItemsByRarity(stakedList, RARE),
            epic: countItemsByRarity(stakedList, EPIC),
            legendary: countItemsByRarity(stakedList, LEGENDARY),
        });

        setDailyDmpReward(
            stakedList.reduce(
                (total, { stakingInfo: { rate } }) => total + Number(rate),
                0
            )
        );
        setTotalDmpReward(
            countTotalUserReward(
                dmpBalance,
                stakedList,
                preLaunchFinishTimestamp
            )
        );
    }, [stakedList, dmpBalance, preLaunchFinishTimestamp]);

    useEffect(() => {
        setUnstakedItemsInfo({
            common: countItemsByRarity(unstakedList, COMMON),
            uncommon: countItemsByRarity(unstakedList, UNCOMMON),
            rare: countItemsByRarity(unstakedList, RARE),
            epic: countItemsByRarity(unstakedList, EPIC),
            legendary: countItemsByRarity(unstakedList, LEGENDARY),
        });
    }, [unstakedList]);

    return (
        <div className="sidebar_block info_block">
            <h3 className="block_title">Staking Info</h3>
            <div className="block_information">
                <div
                    className={classnames('information_item slide', {
                        show: showInformation === 'showInformation',
                    })}
                    onClick={() => activeItemHandler('showInformation')}
                >
                    <div className="information_title">
                        Staked NFTs
                        <div className="value">{stakedList.length}</div>
                    </div>
                    <div className="information_value information__info" />
                    <div
                        className={classnames('information__content', {
                            show: showInformation === 'showInformation',
                        })}
                    >
                        <div className="item">
                            <div className="title">{COMMON}</div>
                            <div className="value">
                                <RarityMarker value="common" />
                                {stakedItemsInfo.common}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{UNCOMMON}</div>
                            <div className="value">
                                <RarityMarker value="uncommon" />
                                {stakedItemsInfo.uncommon}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{RARE}</div>
                            <div className="value">
                                <RarityMarker value="rare" />
                                {stakedItemsInfo.rare}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{EPIC}</div>
                            <div className="value">
                                <RarityMarker value="epic" />
                                {stakedItemsInfo.epic}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{LEGENDARY}</div>
                            <div className="value">
                                <RarityMarker value="legendary" />
                                {stakedItemsInfo.legendary}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={classnames('information_item slide', {
                        show: showInformation === 'Not Staked NFTs',
                    })}
                    onClick={() => activeItemHandler('Not Staked NFTs')}
                >
                    <div className="information_title">
                        Non-staked NFTs
                        <div className="value">{unstakedList.length}</div>
                    </div>
                    <div className="information_value information__info" />
                    <div
                        className={classnames('information__content', {
                            show: showInformation === 'Not Staked NFTs',
                        })}
                    >
                        <div className="item">
                            <div className="title">{COMMON}</div>
                            <div className="value">
                                <RarityMarker value="common" />
                                {unstakedItemsInfo.common}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{UNCOMMON}</div>
                            <div className="value">
                                <RarityMarker value="uncommon" />
                                {unstakedItemsInfo.uncommon}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{RARE}</div>
                            <div className="value">
                                <RarityMarker value="rare" />
                                {unstakedItemsInfo.rare}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{EPIC}</div>
                            <div className="value">
                                <RarityMarker value="epic" />
                                {unstakedItemsInfo.epic}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">{LEGENDARY}</div>
                            <div className="value">
                                <RarityMarker value="legendary" />
                                {unstakedItemsInfo.legendary}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="information_item">
                    <div className="information_title">
                        <span className="title">
                            vDMP daily reward
                            <div className="value">
                                <span>{dailyDmpReward.toFixed(4)}</span>
                            </div>
                        </span>
                        <span
                            className="info_block"
                            onMouseOver={() => showInfo('dmp-daily')}
                            onMouseOut={() => showInfo(false)}
                        >
                            <InfoIcon />
                        </span>
                    </div>

                    <div
                        className={classnames('info_popup', {
                            show: showFaq === 'dmp-daily',
                        })}
                    >
                        Amount of vDMP you receive daily with your current
                        stake.
                    </div>
                </div>

                <div className="information_item">
                    <div className="information_title">
                        <span className="title">
                            vDMP total reward
                            <div className="value">
                                <span>{totalDmpReward.toFixed(4)}</span>
                            </div>
                        </span>
                        <span
                            className="info_block"
                            onMouseOver={() => showInfo('dmp')}
                            onMouseOut={() => showInfo(false)}
                        >
                            <InfoIcon />
                        </span>
                    </div>

                    <div
                        className={classnames('info_popup', {
                            show: showFaq === 'dmp',
                        })}
                    >
                        Total vDMP collected from the Staking Shelter.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoBlock;
